<template>
    <div class="container">
        <div class="panel">
            <div class="two">
                <div class="title">当前位置：专题新闻</div>
                <div class="image" v-for="(item,index) in images" :key="index">
                    <img style="width: 100%" :src="item.face_img" alt="" @click="openArticleDetail(item)">
                </div>
            </div>
            <div>

                <Card title="天气预报" :extra='false'>
                    <img class="weather-image"  style="width: 100%" v-if="weather.length > 0" :src="weather[0].face_img" alt="" @click="openArticleDetail(weather[0])">
                </Card>

                <div style="padding-top: 12px"></div>

                <Story :list="storyList" />
            </div>

        </div>





    </div>
</template>

<script>
import article  from '@/mixins/article'
import Story from '@/components/person-story'

import { fetchList } from '@/api/article'

export default {
    mixins: [article],
    components: {
        Story,
    },

    data() {
        return {
            images: [],
            weather: [],
            storyList: [],
        }
    },

    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            fetchList({
                p: [{
                    // 专题新闻
                    category: "ztxw",
                    page: 1,
                    rows: 100,
                    face: 1
                },
                    {
                        category: 'tqyb',
                        page: 1,
                        rows: 1,
                },
                {
                    // 人物故事
                    category: "rwgs",
                    page: 1,
                    rows: 10,
                }]
            }).then(res => {
                if (Array.isArray(res) && res.length > 0) {
                    if (res[0].data && Array.isArray(res[0].data.list)) {
                        this.images = res[0].data.list;
                    }
                    if (res[1].data && Array.isArray(res[1].data.list)) {
                        this.weather = res[1].data.list;
                    }
                    if (res[2].data && Array.isArray(res[2].data.list)) {
                        this.storyList = res[2].data.list;
                    }
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.two {
    padding: 12px;
}
.title {
    background-color: #ebeff1;
    padding: 12px;
    margin-bottom: 12px;
    color: #787878;
}
.image {
    margin-bottom: 12px;
    cursor: pointer;
}
.image:last-child {
    margin-bottom: 0;
}

.weather-image {
    cursor: pointer;
}
</style>